<template>
  <div id="UnidadesModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Unidades</span>
          <v-btn
            @click="store_Empreendimento.dialogUnidades = false; store_Empreendimento.acao = 'C'"
            icon
            dark
            color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-form 
              v-model="valid" 
              ref="form"
              class="w-100">
              <v-container class="px-1">
                <fieldset class="d-flex flex-column rounded mt-n2 mb-6 pt-2 px-0" style="border: 0;">
                  <legend class="ml-4 font-weight-bold body-2" :style="{ 'color':COR_SUBTITULO }">Dados Básicos</legend>
                  <v-row>
                    <v-col col="6" class="px-2">
                      <v-select
                        id="v-select-situacao"
                        v-model="dados.cod_situacao"
                        class="mb-0 obrigatorio"
                        background-color="#FFF"
                        :items="array_situacoes"
                        :disabled="isAnyOptionDisabled"
                        outlined
                        autofocus
                        label="Situação"
                        filled
                        dense
                        item-text="situacao_nome"
                        item-value="cod_situacao"
                        required
                        :rules="situacaoRules">
                      </v-select>
                    </v-col>
                    <v-col col="6" class="px-2">
                      <v-text-field
                        v-model="dados.situacao_complemento"
                        class="mb-0"
                        light
                        outlined
                        loader-height="1"
                        background-color="#FFF"
                        label="Complemento da Situação"
                        placeholder="Complemento da Situação"
                        filled
                        :maxlength="20"
                        dense>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col col="6" class="pt-0 px-2">
                      <v-select
                        v-model="dados.cod_imoveltipo"
                        class="mt-n2 mb-0 obrigatorio"
                        background-color="#FFF"
                        :items="array_tipo_imovel"
                        outlined
                        label="Tipo imóvel"
                        filled
                        dense
                        item-text = "nome"
                        item-value = "cod_imoveltipo"
                        required
                        :rules="tipoRules">
                      </v-select>
                    </v-col>
                    <v-col col="3" class="pt-0 px-2">
                      <v-select
                        label="Número quadra"
                        v-model="dados.quadra"
                        class="mt-n2 mb-0 obrigatorio"
                        filled
                        outlined
                        dense
                        background-color="#FFF"
                        :items="array_quadras"
                        item-text = "empreend_quadra_nome"
                        item-value = "empreend_quadra_nome"
                        required
                        :rules="numeroQuadraRules">
                      </v-select>
                    </v-col>
                    <v-col col="3" class="pt-0 px-2">
                      <v-text-field
                        v-model="dados.lote"
                        class="mt-n2 mb-0 obrigatorio"
                        light
                        loader-height="1"
                        outlined
                        background-color="#FFF"
                        label="Lote"
                        placeholder="Lote"
                        filled
                        dense
                        required
                        :rules="loteRules">
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col col="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados.area_construida"
                        class="mt-n2 mb-0"
                        light
                        loader-height="1"
                        background-color="#FFF"
                        outlined
                        label="Área construída"
                        placeholder="Área construída"
                        filled
                        dense
                        required>
                      </VuetifyMoney>
                    </v-col>

                    <v-col col="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados.area_metro"
                        class="mt-n2 mb-0 obrigatorio"
                        light
                        loader-height="1"
                        background-color="#FFF"
                        label="Metragem (m²)"
                        placeholder="Metragem (m²)"
                        :disabled="situacao_aux === 'Vendido'"
                        filled
                        outlined
                        dense
                        @input="calcularPreco"
                        required
                        :rules="metragemRules">
                      </VuetifyMoney>
                    </v-col>
                    <v-col col="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados.preco_metro"
                        class="mt-n2 mb-0 obrigatorio"
                        light
                        loader-height="1"
                        background-color="#FFF"
                        label="Preço (m²)"
                        placeholder="Preço (m²)"
                        outlined
                        filled
                        :disabled="situacao_aux === 'Vendido'"
                        dense
                        @input="calcularPreco"
                        required
                        :rules=precoRules>
                      </VuetifyMoney>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col col="6" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados.preco_total"
                        class="mt-n2 mb-0 obrigatorio"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Preço Total (valor)"
                        label="Preço Total (valor)"
                        @input="calcularPrecoMetro"
                        :disabled="situacao_aux === 'Vendido'"
                        required
                        :rules=precoRules>
                      </VuetifyMoney>
                    </v-col>
                    <v-col col="6" class="pt-0 px-2">
                      <v-text-field
                        v-model="dados.fase"
                        class="mt-n2 mb-0"
                        light
                        loader-height="1"
                        background-color="#FFF"
                        outlined
                        label="Fase"
                        placeholder="Fase"
                        filled
                        dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </fieldset>

                <fieldset class="d-flex flex-column rounded mt-n8 mb-6 pt-2 px-0" style="border: 0;">
                  <legend class="ml-4 font-weight-bold body-2" v-bind:style="{ 'color':COR_SUBTITULO }">Dados Complementares</legend>
                  <v-row class="mt-0">
                    <v-col col="6" class="px-2">
                      <v-select
                        label="Localização"
                        v-model="dados.localizacao"
                        class="mt-n3 mb-0 "
                        filled
                        outlined
                        dense
                        background-color="#FFF"
                        :items="array_localizacao">
                      </v-select>
                    </v-col>
                    <v-col col="6" class="px-2">
                      <v-select
                        label="Utilização"
                        v-model="dados.utilizacao"
                        class="mt-n3 mb-0 "
                        outlined
                        filled
                        dense
                        background-color="#FFF"
                        :items="array_utilizacao">
                      </v-select>
                    </v-col>
                  </v-row>
                  
                  <v-row class="mt-0">
                    <v-col col="6" class="pt-0 px-2">
                      <v-select
                        v-model="dados.cod_imovelestado"
                        outlined
                        class="mt-n2 mb-0 "
                        background-color="#FFF"
                        :items="array_estado_imovel"
                        label="Estado do imóvel"
                        filled
                        dense
                        item-text = "nome"
                        item-value = "cod_imovelestado">
                      </v-select>
                    </v-col>
                    <v-col col="6" class="pt-0 px-2">
                      <v-select
                        label="Permite desmembr."
                        v-model="dados.permite_desmembramento"
                        class="mt-n2 mb-0 "
                        outlined
                        filled
                        :disabled="situacao_aux === 'Vendido'"
                        dense
                        background-color="#FFF"
                        :items="array_sim_nao">
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col col="6" class="pt-0 px-2">
                      <v-select
                        label="Posição quadra"
                        v-model="dados.posicao"
                        class="mt-n2 mb-0 "
                        filled
                        outlined
                        dense
                        background-color="#FFF"
                        :items="array_posicao_quadra">
                      </v-select>
                    </v-col>
                    <v-col col="6" class="pt-0 px-2">
                      <v-select
                        label="Caucionado"
                        v-model="dados.caucionado"
                        class="mt-n2 mb-0 "
                        outlined
                        filled
                        :disabled="situacao_aux === 'Vendido'"
                        dense
                        background-color="#FFF"
                        :items="array_sim_nao">
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col col="6" class="pt-0 px-2">
                      <v-text-field
                        v-model="dados.frente_quant"
                        outlined
                        class="mt-n2 mb-0 "
                        light
                        loader-height="1"
                        background-color="#FFF"
                        label="Número frente(s)"
                        placeholder="Número frente(s)"
                        filled
                        dense>
                      </v-text-field>
                    </v-col>
                    <v-col col="6" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados.frente_testada"
                        class="mt-n2 mb-0 "
                        light
                        loader-height="1"
                        outlined
                        background-color="#FFF"
                        label="Testada (ml)"
                        placeholder="Testada (ml)"
                        filled
                        dense>
                      </VuetifyMoney>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col col="6" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados.lado"
                        class="mt-n2 mb-0 "
                        light
                        outlined
                        loader-height="1"
                        background-color="#FFF"
                        label="Lado (ml)"
                        placeholder="Lado (ml)"
                        filled
                        dense>
                      </VuetifyMoney>
                    </v-col>
                    <v-col col="6" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados.fundo"
                        class="mt-n2 mb-0 "
                        light
                        loader-height="1"
                        background-color="#FFF"
                        outlined
                        label="Fundo (ml)"
                        placeholder="Fundo (ml)"
                        filled
                        dense>
                      </VuetifyMoney>
                    </v-col>
                  </v-row>
                  
                  <v-row class="mt-0">
                    <v-col col="6" class="pt-0 px-2">
                      <v-text-field
                        v-model="dados.matricula"
                        class="mt-n2 mb-0 "
                        light
                        outlined
                        loader-height="1"
                        background-color="#FFF"
                        label="Número matrícula"
                        placeholder="Número matrícula"
                        filled
                        dense>
                      </v-text-field>
                    </v-col>
                    <v-col col="6" class="pt-0 px-2">
                      <v-text-field
                      v-model="dados.cadastro_prefeitura"
                      class="mt-n2 mb-0 "
                      light
                      loader-height="1"
                      background-color="#FFF"
                      outlined
                      label="Cadastro prefeitura"
                      placeholder="Cadastro prefeitura"
                      filled
                      dense>
                    </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col col="12" class="pt-0 px-2">
                      <v-textarea
                        v-model="dados.confrontacoes"
                        rows="3"
                        auto-grow 
                        counter="1000" 
                        outlined
                        class="mt-n2 "
                        background-color="#FFF"
                        label="Confrontações"
                        placeholder="Confrontações"
                        filled
                        dense>
                      </v-textarea>
                    </v-col>
                  </v-row> 
                </fieldset>

                <fieldset class="d-flex flex-column rounded mt-n6 mb-6 pt-2 px-0" style="border: 0;">
                  <legend class="ml-4 font-weight-bold body-2" v-bind:style="{ 'color':COR_SUBTITULO }">Dados Contábeis</legend>                   
                  <v-row class="mt-0">
                    <v-col col="6" class="pt-0 px-2">
                      <v-text-field
                        v-model="dados.codigo_contabil"
                        class="mb-0 "
                        light
                        loader-height="1"
                        background-color="#FFF"
                        outlined
                        label="Código contábil"
                        placeholder="Código contábil"
                        filled
                        dense>
                      </v-text-field>
                    </v-col>
                    <v-col col="12" class="pt-0 px-2">
                      <v-text-field
                        v-model="dados.controle_iptu"
                        class="mb-0 "
                        light
                        loader-height="1"
                        background-color="#FFF"
                        outlined
                        label="Número controle iptu"
                        placeholder="Número controle iptu"
                        filled
                        dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  
                  <v-row class="mt-0">
                    <v-col col="12" class="pt-0 px-2">
                      <v-select
                        label="Proprietário"
                        v-model="dados.cod_proprietario"
                        class="mt-n2 mb-0 "
                        outlined
                        filled
                        dense
                        background-color="#FFF"
                        :items="array_proprietarios"
                        item-text = "pessoa_nome"
                        item-value = "cod_empreend_proprietario">
                      </v-select>
                    </v-col>
                  </v-row>
                </fieldset>
              </v-container>
                 
            </v-form>
          </v-row>
        </v-container>
      </v-card-text>
      
      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Empreendimento.dialogUnidades = false; store_Empreendimento.acao = 'C'; "
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text>
          Cancelar
        </v-btn>

        <v-btn
        :loading="loading"
        @click="validate()"
        :disabled="!valid"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import store_Empreendimento from "../store_Empreendimento";
import { COR_PRINCIPAL, COR_SUBTITULO } from "../../../services/constantes";
import store_site from "../../../store/store_site";
import Vue from "vue";
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue"


export default {
  name: "UnidadesModal",

  props: {
    situacao_aux : {
    type : String
    },
    array_unidades_detalhes : {
      type : Object
    }
  },


  components: {
    VuetifyMoney
  },

  data() {
    return {
      store_Empreendimento : store_Empreendimento,
      
      store_site           : store_site,
      COR_PRINCIPAL        : COR_PRINCIPAL,
      COR_SUBTITULO        : COR_SUBTITULO,  
      loading              : false,
      valid                : true,
      form                 : false,
      array_tipo_imovel    : [],
      array_situacoes      : [],
      unidades             : {},
      array_quadras        : [],
      array_proprietarios  : [],
      dados                : {},

      array_sim_nao       : [
        "SIM", "NÃO",
      ],
      array_localizacao   : [
        "URBANO", "RURAL"
      ],
      array_utilizacao    : [
        "COMERCIAL", "INDUSTRIAL", "INSTITUCIONAL", "MISTO", "PUBLICO", "RESIDENCIAL"
      ],
      array_posicao_quadra: [
        "MEIO DE QUADRA", "ESQUINA"
      ],
      array_estado_imovel : [
        { nome: "EM CONSTRUCAO",  cod_imovelestado: 1 },
        { nome: "MURO DE ARRIMO", cod_imovelestado: 2 },
        { nome: "LIMPO",          cod_imovelestado: 3 },
        { nome: "CAUCIONADO",     cod_imovelestado: 4 },

      ],

      tipoRules: [
        (value) => !!value || "O tipo é obrigatório",
      ],
      localizacaoRules: [
        (value) => !!value || "A localização é obrigatória",
      ],
      utilizacaoRules: [
        (value) => !!value || "A utilização é obrigatória",
      ],
      numeroQuadraRules: [
        (value) => !!value || "O número da quadra é obrigatório",
      ],
      loteRules: [
        (value) => !!value || "O lote é obrigatório",
        (value) => /^\d+$/.test(value) || 'O lote não é válido',
        (value) =>
          (value && String(value).length <= 10) ||
          "O lote deve ter menos do que 10 caracteres",
      ],
      metragemRules: [
        (value) => !!value || "A metragem é obrigatória",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      precoRules: [
        (value) => !!value || "O preço é obrigatório",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0"
      ],
      situacaoRules: [
        (value) => !!value || "A situação é obrigatória",
      ],
      situacaocomplementoRules: [
        (value) =>
        (!value || value.length <= 20) ||
          "Deve ter no máximo 20 caracteres" + value && value.length,
      ],
      estadoImovelRules: [
        (value) => !!value || "O estado do imóvel é obrigatório",
      ],
      permiteDesmembramentoRules: [
        (value) => !!value || "Permite desmembramento é obrigatório",
      ],
      posicaoQuadraRules: [
        (value) => !!value || "A posição da quadra é obrigatória",
      ],
      numeroFrenteRules: [
        (value) => !!value || "Número frente(s) é obrigatório",
        (value) => /^\d+$/.test(value) || 'Número frente(s) não é válido',
        (value) =>
          (value && value <= 9999999999) ||
          "Número frente(s) deve ter menos do que 10 caracteres",
      ],
      testadaRules: [
        (value) => !!value || "Testada (ml) é obrigatória",
        (value) =>
          (value && value.length <= 10) ||
          "Testada (ml) deve ter menos do que 8 caracteres",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      ladoRules: [
        (value) => !!value || "O lado (ml) é obrigatório",
        (value) =>
          (value && value.length <= 10) ||
          "O lado (ml) deve ter menos do que 8 caracteres",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      fundoRules: [
        (value) => !!value || "O fundo (ml) é obrigatório",
        (value) =>
          (value && value.length <= 10) ||
          "O fundo (ml) deve ter menos do que 8 caracteres",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      numeroMatriculaRules: [
        (value) => !!value || "O número matrícula é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número matrícula não é válido',
        (value) =>
          (value && value.length <= 40) ||
          "O número matrícula deve ter menos do que 40 caracteres",
      ],
      cadastroPrefeituraRules: [
        (value) => !!value || "O cadastro prefeitura é obrigatório",
        (value) =>
          (value && value.length <= 30) ||
          "O cadastro prefeitura deve ter menos do que 30 caracteres",
      ],
      numeroControleIptuRules: [
        (value) => !!value || "O n° controle iptu é obrigatório",
        (value) => /^\d+$/.test(value) || 'O n° controle iptu não é válido',
        (value) =>
          (value && value.length <= 12) ||
          "O n° controle iptu deve ter menos do que 12 caracteres",
      ],
      proprietarioRules: [
        (value) => !!value || "O proprietário é obrigatório",
      ],
      faseRules: [
        (value) => !!value || "A fase é obrigatória",
        (value) =>
          (value && value.length <= 20) ||
          "A fase deve ter menos do que 20 caracteres",
      ],
      codigoContabilRules: [
        (value) => !!value || "O código contábil é obrigatório",
        (value) => /^\d+$/.test(value) || 'O código contábil não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O código contábil deve ter menos do que 10 caracteres",
      ],
      caucionadoRules: [
        (value) => !!value || "Caucionado é obrigatório",
      ],
      confrontacoesRules: [
        (value) => !!value || "A confrontação é obrigatória",
        (value) =>
          (value && value.length <= 1000) ||
          "A confrontação deve ter menos do que 1000 caracteres",
      ],
    };
  },

  computed: {
    isAnyOptionDisabled() {
      return this.array_situacoes?.some(option => {
        if(!!option && this.dados.cod_situacao == option.cod_situacao && !!option.disabled){
          return true
        }
        else{
          return false
        }
      })
    }
  },

  methods: {
    async validate() {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {

        // this.unidades  = this.store_Empreendimento.array_unidades_detalhes;
        this.dados.cod_empreendimento = this.$route.params.cod_empreendimento;

        var lo_JSON = {
          ...this.dados,
        };
        console.log("🚀 ~ file: UnidadesModal.vue:682 ~ validate ~ lo_JSON:", lo_JSON)
        // return

        var ls_Res;
        this.loading = true;

        if (this.dados.acao == "E") {
          console.log("🚀 ~ validate ~ E")
          ls_Res = await this.store_Empreendimento.imovelPut(lo_JSON);
        }else{
          console.log("🚀 ~ ImovelPost")

          ls_Res = await this.store_Empreendimento.ImovelPost(lo_JSON);
        }
        this.loading = false;

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor     = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg     = ls_Res.result.result.trim();
          this.store_site.alert         = true;

          if(this.dados.acao == "I" || this.dados.acao == "IS"){
            const la_Unidades = await this.store_Empreendimento.getImovel({ cod_empreendimento: this.$route.params.cod_empreendimento });
            this.store_Empreendimento.array_unidades = la_Unidades.rows;
          }
          
          if(this.dados.acao == "E"){
            let lo_Item = await this.store_Empreendimento.getImovel({cod_imovel: this.unidades.cod_imovel, cod_empreendimento: this.$route.params.cod_empreendimento});
            lo_Item = lo_Item.rows[0]
            // Busca a posição no array da opção selecionada na grade
            let li_Item = await this.store_Empreendimento.array_unidades.findIndex((val) => (val.cod_empreendimento == lo_Item.cod_empreendimento &&
                                                                         val.cod_imovel         == lo_Item.cod_imovel));
           
            // Seta de volta o valor que está no banco quando o usuario clica em cancelar, de forma reativa
            Vue.set(this.store_Empreendimento.array_unidades, li_Item, lo_Item);
          }
  
        }else {
          this.store_site.alert_cor     = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg     = ls_Res.errors.trim();
          this.store_site.alert         = true;
        }
        if(this.dados.acao === "IS"){ 
          this.store_Empreendimento.array_unidades_detalhes.lote = parseFloat(this.store_Empreendimento.array_unidades_detalhes.lote) + 1;
        }
      }

      if(this.store_Empreendimento.acao !== "IS"){
        this.store_Empreendimento.dialogUnidades = false;
      }
    },

    async getTipoImovel(){
      const la_TipoImovel = await this.store_Empreendimento.getTipoImovel({ cod_empreendimento: this.$route.params.cod_empreendimento });
      this.array_tipo_imovel = la_TipoImovel;
    },

    async getSituacoes(){
      const la_Situacoes = await this.store_Empreendimento.getSituacoes({ cod_empreendimento: this.$route.params.cod_empreendimento, });
      this.array_situacoes = la_Situacoes.map(option => {
        // Verifica se a opção é do tipo 'Mapa de Venda' e se o status é permitido
        if (option.situacao_tipo === 'Mapa de Venda' && ["INDISPONIVEL", "RESERVADO", "DISPONIVEL", "PRE RESERVADO"].includes(option.situacao_sistema)) {
          return {
            ...option,
            disabled: false
          };
        } else if (option.situacao_tipo === 'Mapa de Venda') {
          // Retorna as opções do tipo 'Mapa de Venda' que não estão na lista permitida
          return {
            ...option,
            disabled: true
          };
        }
      });

      console.log('situacao_aux', this.situacao_aux)

      // this.array_situacoes = la_Situacoes.filter((value) => {value.situacao_tipo === "Mapa de Venda" && (value.situacao_nome != "Reservado Corretor")

      // } )
    },

    async getQuadras(){
      const la_Quadras = await this.store_Empreendimento.getQuadras({ cod_empreendimento: this.$route.params.cod_empreendimento });
      this.array_quadras = la_Quadras;
    },

    async getProprietarios(){
      const la_Proprietarios = await this.store_Empreendimento.getProprietarios({ cod_empreendimento: this.$route.params.cod_empreendimento });
      this.array_proprietarios = la_Proprietarios;
    },

    calcularPreco(){
      if(!!this.dados.area_metro && !!this.dados.preco_metro){
        this.dados.preco_total = this.dados.area_metro * this.dados.preco_metro
      }
    },

    calcularPrecoMetro(){
      if(this.dados.area_metro){
        this.dados.preco_metro =
          this.dados.preco_total / this.dados.area_metro
      }
    }



  },

  async mounted() {
    await this.getSituacoes();
    await this.getTipoImovel();
    await this.getQuadras();
    await this.getProprietarios();

    if(!!this.array_unidades_detalhes){
      this.dados = {...this.array_unidades_detalhes}
    }
    else{
      this.dados = {}
    }

    // console.log('array_situacoes',  this.array_situacoes); //
    // console.log('cod_situacao', this.dados.cod_situacao)
    // this.array_situacoes.map(el => {
    //   if (el?.disabled && this.dados.cod_situacao == el?.cod_situacao){
    //     console.log(document.getElementById('v-select-situacao').disabled = true, document.getElementById('v-select-situacao').ariaReadOnly);
    //   }
    // })
  },


};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.w-100 {
  width: 100%!important;
}

.btn {
  width: 100px;
}
</style>