<template>
  <div id="ImoveisPlanilha"  class="pa-2 pt-0 pb-0">
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center  body-1 font-primary ml-2">
      <v-btn
        icon
        dark
        color="grey"
        class="btn-icon mr-1">
        <v-icon
          @click="store_Empreendimento.importarPlanilha = false"
          color="#84A0CD"
          class="icon-action">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <p
        class="font-primary font-weight-bold font-weight-medium body-1 mb-0"
        v-bind:style="{ 'color':COR_SUBTITULO }">
        Importação de Dados
      </p>

        <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>

    <v-row class="w-100 pt-4">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="w-100 pt-0 pb-5">
        <v-container class="px-0 w-100 mx-0 pt-0" >
        <!-- Conteiner Principal  -->
          <!-- Form Cadastro -->
          <v-row class="w-100 justify-center" >
            <v-col cols="12" class="pb-0 pt-1">
              <v-stepper v-model="e1" alt-labels elevation="0" style="background-color:transparent!important">
                <v-stepper-header v-if="e1 > 1" class="steps" elevation="0" rounded style="background-color:white!important">
                  <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                    :color="e1 > 1 ? 'green' : 'primary'"
                  >
                    Upload
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                  >
                    Importação
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                     <v-row class="justify-center">
                        <v-col cols="12" sm="6" lg="5">
                                  <v-card class="pa-6 card" elevation="1" style="height:99%!important">
                                      <v-icon x-large class="upload-excel">mdi-microsoft-excel</v-icon>

                                    <v-card-title class="card-title">Download planilha importação v.001</v-card-title>
                                    <v-card-text>Se você ainda não possui a planilha de esboço para importar os dados do imóvel, clique no botão abaixo para fazer o download.</v-card-text>
                                    <v-card-actions class="pr-16">
                                      <v-btn 
                                        class="text-none btn-download d-flex align-center"
                                        color="primary"
                                        href="/download/Planilha_de_Importacao_de_Imoveis_de_Empreendimento_-_YouNect -1.0.xlsx"
                                        download>
                                        <v-icon left>
                                          mdi-table-arrow-down
                                        </v-icon>
                                        Baixar Planilha
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-col>

                                <!-- <v-col cols="1"></v-col> -->

                                <v-col cols="12" sm="6" lg="5">

                                  <v-card class="pa-6 card" elevation="1" style="height:99%!important">
                                      <v-icon x-large class="upload-excel">mdi-microsoft-excel</v-icon>
                                    <v-card-title class="card-title">Upload planilha importação v.001</v-card-title>
                                    <v-card-text>Se você já possui o modelo de planilha v.001, e preencheu os dados corretamente, clique no botão abaixo para enviar e carregar os dados no sistema.</v-card-text>
                                      <v-row>
                                        <v-file-input
                                          v-model="upload_planilha"
                                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                          chips
                                          class="input-upload"
                                          label="Enviar planilha preenchida">
                                        </v-file-input>
                                      </v-row>
                                      <v-row>
                                      <v-btn 
                                        class="text-none d-flex align-center"
                                        @click="populaTabela(); step = 2"
                                        :disabled="upload_planilha == null"
                                        color="primary">
                                          <v-icon left>
                                            mdi-table-arrow-up
                                          </v-icon>
                                        Iniciar importação
                                      </v-btn>
                                      </v-row>
                                  </v-card>
                                </v-col>

                           </v-row>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                      <v-row class="justify-center">
                            <v-col cols="12 pl-0 pr-0 pb-0">
                            <h4 class="mt-0 mb-0 pt-2 text-center">
                              Confira todos os dados importados na tabela.
                              Se preciso, edite os dados necessários antes de salvar.
                            </h4>
                            <h4 class="mt-0 mb-0 pt-0 text-center">
                              Registros existentes da mesma unidade serão alterados.
                              Registros de unidades não localizadas serão adicionados.
                            </h4>
                            <h4 class="mt-0 mb-2 pt-0 text-center" style="color:red;">
                              Certifique-se que outra pessoa não está alterando os dados desse mesmo empreendimento.
                            </h4>

                              <div class="d-flex flex-column justify-space-between mt-0 mb-1">   
                                <!-- TABELA -------------------------------------------------------->
                                <v-data-table
                                :items="array_planilha_table"
                                :headers="headers"
                                :loading="loading"
                                fixed-header
                                dense
                                :items-per-page="10000"
                                :height="tableHeight"
                                hide-default-footer
                                loading-text="Carregando...  aguarde..."
                                no-data-text="Nenhum Registro Encontrado"
                                no-results-text="Nenhum Registro Encontrado"
                                >
                              <template #item="{ item }">
                                  <tr :style="{ 'background':item.cor }">
                                    <td>
                                      {{ item.quadra }}
                                    </td>
                                    <td>
                                      {{ item.lote }}
                                    </td>
                                    <td>
                                      {{ item.posicao }}
                                    </td>
                                    <td>
                                      {{ item.area_metro ? formatNumber(parseFloat(item.area_metro)) : '' }}
                                    </td>
                                    <td>
                                      {{ item.area_construida ? formatNumber(parseFloat(item.area_construida)) : '' }}
                                    </td>
                                    <td>
                                      {{ item.frente_quant }}
                                    </td>
                                    <td>
                                      {{ item.frente_testada ? formatNumber(parseFloat(item.frente_testada)) : '' }}
                                    </td>
                                    <td>
                                      {{ item.lado ? parseFloat(item.lado).toFixed(4).replace('.',',') : '' }}
                                    </td>
                                    <td>
                                      {{ item.fundo ? parseFloat(item.fundo).toFixed(4).replace('.',',') : '' }}
                                    </td>
                                    <td>
                                      {{ item.preco_metro ? parseFloat(item.preco_metro).toFixed(4).replace('.',',') : '' }}
                                    </td>
                                    <td>
                                      {{ item.preco_total ? formatNumber(parseFloat(item.preco_total)) : '' }}
                                    </td>
                                    <td>
                                      {{ item.tipo_imovel }}
                                    </td>
                                    <td>
                                      {{ item.situacao }}
                                    </td>
                                    <td>
                                      {{ item.estado }}
                                    </td>
                                    <td>
                                      {{ item.matricula }}
                                    </td>
                                    <td>
                                      {{ item.cadastro_prefeitura }}
                                    </td>
                                    <td>
                                      {{ item.confrontacoes }}
                                    </td>
                                    <td>
                                      {{ item.utilizacao }}
                                    </td>
                                    <td>
                                      {{ item.localizacao }}
                                    </td>
                                    <td>
                                      {{ item.permite_desmembramento }}
                                    </td>
                                    <td>
                                      {{ item.caucionado }}
                                    </td>
                                    <td>
                                      {{ item.fase }}
                                    </td>
                                  </tr> 

                              </template>
                            </v-data-table>
                          <!-- FIM TABELA -------------------------------------------------------->
                        </div>

                      <div class="d-flex justify-center">
                          <v-btn
                              id="btn_cancelar"
                              ref="btn_cancelar"
                              class="mr-4"
                              color="primary"
                              text
                              @click="btnCancelar"
                            >
                              Cancelar
                            </v-btn>

                          <v-btn
                            class="btn white--text"
                            color="primary accent-4"
                            v-on:click="validate()"
                            :loading="loading"
                          >
                            Salvar
                          </v-btn>
                        </div>
                       </v-col>

                      </v-row>
                  </v-stepper-content>

                </v-stepper-items>
              </v-stepper>


            </v-col>
          </v-row>

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>
    <!-- <Footer /> -->
   
  </div>
</template>

<script>
import { COR_PRINCIPAL, COR_SUBTITULO } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";
import { HyperFormula } from 'hyperformula';
import { read, utils } from 'xlsx';
import store_site from "../../../store/store_site";
import { nextTick } from "process";
import { formatNumber, duplicateKeysInArray,formatPrice } from "../../../services/funcoes";

const FileType = require("file-type");

export default {
  name: "ImoveisPlanilha",

  components: {
  },

  data() {
    return {

      store_Empreendimento  : store_Empreendimento,
      store_site            : store_site,

      HyperFormula          : HyperFormula,
      read                  : read,
      utils                 : utils,
      formatNumber          : formatNumber,
      formatPrice           : formatPrice,

      COR_SUBTITULO         : COR_SUBTITULO,
      COR_PRINCIPAL         : COR_PRINCIPAL,

      e1      : 1,
      step    : 1,

      importacao : 1,
      importacao_workbook1 : null,
      importacao_workbook2 : null,

      dados: {
          cod_usuario: null,
          nome: "",
          cpf: "",
          email: "",
          celular: "",
          nascimento_dt: null,
          profissao: null,
          estado_civil: null,
          situacao: null,
          foto: null,
          usuario_funcao: [],
      },

      empreendimentos: ['RESIDENCIAL RESERVA DOS IPÊS', 'RESIDENCIAL TELLINI', 'JARDIM BOTÂNICO', 'JARDIM COONAI'],

      options         : null,
      upload_planilha : null,
      workbook        : null,
      hf              : null,
      array_planilha  : [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],
      array_planilha_table : [],

      loading: false,

       /* Título tabela */
      headers: [
        { text: "Quadra", value: "quadra" },
        { text: "Lote", value: "lote" },
        { text: "Posicao", value: "posicao" },
        { text: "Area metro", value: "area_metro" },
        { text: "Area construida", value: "area_construida" },
        { text: "Frentes", value: "frente_quant" },
        { text: "Frente testada", value: "frente_testada" },
        { text: "Lado", value: "lado" },
        { text: "Fundo", value: "fundo" },
        { text: "Preco do metro", value: "preco_metro" },
        { text: "Preco total", value: "preco_total" },
        { text: "Tipo imovel", value: "tipo_imovel" },
        { text: "Situacao", value: "situacao" },
        { text: "Estado", value: "estado" },
        { text: "Matrícula", value: "matricula" },
        { text: "Cadastro prefeitura", value: "cadastro_prefeitura" },
        { text: "Confrontacoes", value: "confrontacoes" },
        { text: "Utilização", value: "utilizacao" },
        { text: "Localização", value: "localizacao" },
        { text: "Permite desmembramento", value: "permite_desmembramento" },
        { text: "Caucionado", value: "caucionado" },
        { text: "Fase", value: "fase" },
        
      ],


      /* Endereco tabela */
        endereco: [
        
        {rua: "Thomaz Gonzaga", numero: "1869", complemento: "3 andar", bairro: "Centro", cep: "14.400-540", cidade: "Franca - SP"},
     ],
 
      /* Formulário */
      form: false,
    };
  },

  created() {
    // define the options
    this.options = {
      //language: "ptPT",
      licenseKey: 'gpl-v3',
      //precisionRounding: 4,
      //nullDate: { year: 1900, month: 1, day: 1 },
      functionArgSeparator: ';',
      evaluateNullToZero: true,
      //decimalSeparator: ',',
      //thousandSeparator: '.',
    };
    this.hf = HyperFormula.buildEmpty(this.options);
  },

  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 90;
      else{
        return window.innerHeight - 430;

      }
    }
  },

  methods:{
    btnCancelar(){
      this.e1 = 1; 
      this.upload_planilha = null;
      this.step = 1;
      this.array_planilha = []
    },

    async validate() {
      this.loading = true;
      this.store_site.overlay_global = true
      let ls_Res = await this.store_Empreendimento.ImovelPostPlanilha(this.array_planilha);
      if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor     = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg     = ls_Res.result.result.trim();
          this.store_site.alert         = true;
  
        }else {
          this.store_site.alert_cor     = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg     = ls_Res.errors.trim();
          this.store_site.alert         = true;
        }
        
        this.store_site.overlay_global = false
        this.loading = false;
        this.store_Empreendimento.itemSelecionado = 7
    },

    async populaTabela() {
      // define the options
      this.hf = HyperFormula.buildEmpty(this.options);

      let file = this.upload_planilha;
      if (file) {
        const reader = new FileReader();

        // Ao terminar de abrir o arquivo, processar os comandos
        this.workbook = null;
        reader.onload = async (e) => {
          try {

            // Extraindo apenas o conteudo do base 64 sem o cabecalho (tipo de arquivo)
            const base64String = e.target.result
                                  .replace('data:', '')
                                  .replace(/^.+,/, '');
            this.workbook = read(base64String);
            if(this.importacao == 2) {
              this.importacao_workbook2 = this.workbook;
              //if (this.importacao_workbook1 != this.importacao_workbook2)
              //  console.log('DIFERENTE !!!!!!!!!!!');
              //  else
              //  console.log('igual')
            }
            if(this.importacao == 1) {
              this.importacao_workbook1 = this.workbook;
              this.importacao++
            }

            // Build HyperFormula sheets
            const senha = this.workbook.SheetNames[1];
            const sheet = this.workbook.Sheets[senha];
            const hf_sheet = this.hf.addSheet(senha);
            const hf_sheetId = this.hf.getSheetId(hf_sheet);
            let planilha2 = []
            for (const [key, cell] of Object.entries(sheet)) {
              if (key.startsWith("!"))
                continue; // skip meta data              
              const address = this.hf.simpleCellAddressFromString(key, hf_sheetId);
              if (cell.f) {
                const value = `=${cell.f}`;
                if (!planilha2[address.row] || planilha2[address.row] == [])
                  planilha2[address.row] = [];
                planilha2[address.row][address.col] = String(value).replaceAll(',',';');
              } else {
                const value = cell.v;
                if (!planilha2[address.row] || planilha2[address.row] == [])
                  planilha2[address.row] = [];
                  planilha2[address.row][address.col] = String(value) //.replaceAll(',','').replaceAll('%','');
              }
            }
            if(planilha2[0][0] !== "PLANILHA_VISCOM@123")
            {
              this.store_site.alert_timeout = 10000;
              this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
              this.store_site.alert_msg = "Esta planilha não foi baixada do nosso sistema, baixe-a e tente novamente.";
              this.store_site.alert = true;

              return
            }

            const name = this.workbook.SheetNames[0];
            const sheet2 = this.workbook.Sheets[name];
            const hf_sheet2 = this.hf.addSheet(name);
            const hf_sheetId2 = this.hf.getSheetId(hf_sheet2);
            for (const [key, cell] of Object.entries(sheet2)) {
              if (key.startsWith("!"))
                continue; // skip meta data              
              const address = this.hf.simpleCellAddressFromString(key, hf_sheetId2);
              if (cell.f) {
                const value = `=${cell.f}`;
                if (!this.array_planilha[address.row] || this.array_planilha[address.row] == [])
                  this.array_planilha[address.row] = [];
                this.array_planilha[address.row][address.col] = String(value).replaceAll(',',';');
              } else {
                const value = cell.v;
                if (!this.array_planilha[address.row] || this.array_planilha[address.row] == [])
                  this.array_planilha[address.row] = [];
                  this.array_planilha[address.row][address.col] = String(value) //.replaceAll(',','').replaceAll('%','');
              }
            }
            if(this.array_planilha[0][1] !== "1.0") {
              this.store_site.alert_timeout = 10000;
              this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
              this.store_site.alert_msg = "Esta planilha não é a versão atual, baixe-a e tente novamente.";
              this.store_site.alert = true;
              return
            }

            let lo_params = {}
            lo_params.planilha = 'planilha'
            lo_params.cod_empreendimento = this.$route.params.cod_empreendimento
            let la_Unidades = await this.store_Empreendimento.getImovel(lo_params);
            if (la_Unidades == 'Não possui dados') {
              la_Unidades = []
            }
            
            // Alterando os elementos da linhade dados da planilha original para apenas um array de varios elementos
            let _array_planilha       = this.array_planilha;
            let _array_planilha_table = [];

            for (let indice = 0; indice < this.array_planilha.length; indice++) {
              const item = this.array_planilha[indice];
              if (indice >= 5 && item.length > 0) {
                let lObj  = new Object();
                lObj["cod_empreendimento"] = lo_params.cod_empreendimento;
                // buscando nos imoveis que vem do banco para adicionar cor e cod imovel
                let lo_Unidade = la_Unidades.find((o) => (o.quadra === item[0] && o.lote === item[1]));
                if (lo_Unidade) {
                  lObj.cor = '#fefbd9'
                  lObj.cod_imovel = lo_Unidade.cod_imovel;
                  lObj.acao = 'E';
                }
                else {
                  lObj.cor = '#eaf4df'
                  lObj.cod_imovel = null;
                  lObj.acao = 'I';
                  // console.log("NAO ACHOU:::", lObj);
                }
                
                // colocando um objeto na linha para poder acessar a chave e não o número
                for (let index = 0; index < item.length; index++) {
                  if (item[index] && item.length > 0)
                      lObj[_array_planilha[4][index]] = item[index];
                }

                // igualando os 2 array, o array_planilha que vai ser enviado para o back e o 
                // array_planilha_table que está preparado somente para o front
                if (item.length > 0) {
                  _array_planilha[indice] = lObj
                  _array_planilha_table.push(lObj);
                }
              }
            };
            // pegando as variaveis locais que estavam dentro do loop e jogando os valores 
            // para variaveis acessiveis para fora do metodo
            this.array_planilha_table = _array_planilha_table;
            this.array_planilha       = _array_planilha;

            // Verifica se possuem quadra, lote duplicados
            const unique = duplicateKeysInArray(this.array_planilha_table, ['quadra','lote'])
            if (unique) {
              this.store_Empreendimento.itemSelecionado = 7
              this.store_site.alert_timeout = 10000;
              this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
              this.store_site.alert_msg = 'Corrija a planilha e importe novamente. A Quadra:' + unique.quadra + ' / Lote:' + unique.lote + ' estão duplicados.';
              this.store_site.alert = true;
              return;
            }            
            //passando para o proximo step
            if (this.array_planilha_table.length > 0) {
              this.e1 = 2
            }
            else{
              this.store_site.alert_timeout = 10000;
              this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
              this.store_site.alert_msg = "A planilha não contém dados.";
              this.store_site.alert = true;
              return
            }

          }
          catch(err) {
            this.store_site.alert_timeout = 10000;
            this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
            this.store_site.alert_msg = err;
            this.store_site.alert = true;
            console.error(err);
          }
        }
        reader.readAsDataURL(file); // convert to base64 string
    console.log("🚀 ~ file: ImoveisPlanilha.vue:396 ~ created ~ this.hf:", this.hf)

      }
    }
  }

};
</script>

<style scoped>
#ImoveisPlanilha{
  height: 100%;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  position: relative;
  clear: both;
  width: 100%;
}
@media (min-width: 1264px){
  .container {
      max-width: 100%;
      padding-bottom: 0;
  }
}

@media (min-width: 960px){
  .container {
      max-width: 100%;
      padding-bottom: 0;
  }
}
.v-stepper__content{
  padding: 0;
}
  .main-title {
    background: #0548a3;
  }

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .container-principal {
    max-width: calc(100% - 32px) !important;
    margin-bottom: 20px !important;
  }

  .container-rounded {
    max-width: calc(100% - 32px) !important;
    background: #F2F6F7 !important;
    border-radius: 12px !important;
    margin: auto !important;
    margin-bottom: 20px !important;
    padding: 20px 13px 13px 13px !important;
  }

  /* ======================== AVATAR UPLOAD ===================== */
  .mx-auto {
    margin: auto;
  }

   .input-file {
    width: 110px !important;
    height: 35px !important;
    border-radius: 50%;
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    outline: none !important;
    z-index: 2;
  }

  .avatar-container__file {
    width: 100%;
    height: 100%;
    margin: 0px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    outline: none;
  }

  /* ======================== PRIVILEGES ===================== */
  .h5 {
    font-family: "Montserrat", sans-serif;
    color: rgba(0, 0, 0, 0.87);
  }

  .h5::after {
    display: block;
    width: 50px;
    height: 3px;
    margin-top: 0.2rem;
    content: "";
    background: #274abb;
  }

  .btn {
    width: 120px;
  }

  .v-btn {
    display: inline-block;
  }

  /* CELULAR */
  @media (max-width: 599px) {

    .container,
    .container-principal {
      margin: 0px !important;
      padding: 0px 8px !important;
    }

    .container-rounded {
      background: #F2F6F7 !important;
      border-radius: 12px !important;
      max-width: calc(100%) !important;
      margin: auto !important;
      padding: 20px 13px 13px 13px !important;
    }

    .title-page {
      margin-left: 16px !important;
    }

    .theme--light.v-data-table {
      border-radius: 12px 12px 0px 0px !important;
    }

    .avatar {
      margin: 0px 0px 0px 4px !important;
    }
    
    .status-mobile {
      display: block;
    }

    .btn-icon {
      margin-right: 0px !important;
    }
    .search-input {
      margin: 0.8rem 0 1.2rem 0;
      min-width: auto;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      height: 35px !important;
      /* min-width: calc(100% - 15px); */
    }
    .search-input--outlined {
      background-color: #FF0000;
    }
  }

.upload-excel {
  font-size: 60px !important;
  color: #274abb;
  opacity: 0.4;
}

.steps {
margin: -10px 125px 0px; 
box-shadow: 0px!important;
}

.v-stepper__header {
box-shadow: 0px!important;
height: 77px;
}

@media(min-width: 1440px){
.steps {
  margin: -10px 335px 0px; 
  box-shadow: 0px!important;
}

.btn-download {
margin-top: 36px!important;
}
}

@media(max-width: 1440px) {
.steps {
margin: -10px 125px 0px; 
box-shadow: 0px!important;
}

.btn-download {
margin-top: 36px!important;
}
}

@media(max-width: 1280px) {
  .steps {
margin: -10px 150px 0px; 
}

.btn-download {
margin-top: 15px!important;
}
}

@media(max-width: 768px) {
  .steps {
margin: 20px 80px 0px; 
}

.card-title {
  font-size: 0.98rem;
}

.btn-download {
margin-top: 36px!important;
}

.container-upload {
  padding-right: 0px!important;
}

.input-upload {
  margin-right: 0px!important;
}
}

.container-select {
  max-width: 400px;
}

@media(max-width: 768px) {
  .container-select {
  max-width: 300px;
}
}

.v-stepper__wrapper{
  padding-bottom: 20px;
}
</style>