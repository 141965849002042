<template>
  <div id="Unidades" >
    <v-tabs
      id="tabs"
      v-model="tab"
      background-color="transparent"
      slider-size="2"
      slider-top="10"
      tabindex="-1"
      active-class="active">
    
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-10 "
        :style="{ 'background-color': tab == 0 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"> 
        <p
          class="font-primary body-1 mt-2"
          :style="{ 'color': tab == 0 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Unidades
        </p>
      </v-tab>
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-4 "
        :style="{ 'background-color': tab == 1 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"> 
        <p
          class="font-primary body-1 mt-2"
          :style="{ 'color': tab == 1 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Mapas
        </p>
      </v-tab>
    </v-tabs>

    <v-row class="ml-2 mr-2">
      <v-container class="container mt-3 mx-0 pt-3 rounded-lg">
        <div v-if="tab == 0">
          <!-- FIM TÍTULO DA PÁGINA -->
          <!-- Text Field com Consulta ----------------------------------------->
          <v-container class="pa-0 ma-0 mb-3 d-flex" style="margin-top: -4px;" >
            <v-menu
              id="vMenu"
              ref="vMenu"
              v-model="menu"
              bottom
              right
              fluid
              offset-y
              origin="top"
              class="pa-0 d-flex flex-wrap"
              elevation=0
              :close-on-content-click="false"
              @input="abriuConsulta"
            >
            <template v-slot:activator="{ on }">
              <div
                ref="vMenuDiv"
                class="false-filter d-flex align-center justify-space-between"
                :style="{ 'background-color':'FAFAFB' }"
                v-on="on">
                <div class="d-flex">
                  <v-icon class="false-icon ml-3 mr-2" :color="COR_SUBTITULO">mdi-magnify</v-icon>
                  <div class="false-text" style="flex:none">
                    <!-- {{ store_Empreendimento.filtro_unidades }} -->
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.quadra"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Quadra: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.quadra.empreend_quadra_nome}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.lote"
                      style="max-width: 250px;" 
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Lote: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.lote}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.cod_situacao"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1 pa-2">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Situação: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.cod_situacao.situacao_nome}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.caucionado"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Caucionado: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.caucionado}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.preco_total_min"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Preço min: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.preco_total_min}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.preco_total_max"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Preço max: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.preco_total_max}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.fase"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Fase: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.fase}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.permite_desmembramento"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Desmembra: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.permite_desmembramento}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.area_metro_min"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span :style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Metragem Inicial: </span>
                      <strong :style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.area_metro_min}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Empreendimento.filtro_unidades.area_metro_max"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span :style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Metragem Final: </span>
                      <strong :style="{ 'color':COR_PRINCIPAL }">{{store_Empreendimento.filtro_unidades.area_metro_max}}</strong>
                    </v-chip>
                    
                  </div>
                  </div>
                  <v-icon class="false-icon mr-4 ml-2" :color="COR_SUBTITULO">mdi-filter-variant-plus</v-icon>
                </div>
              </template>
              <v-container 
                :style="{ 'background-color':COR_BACKGROUND+'!important', 'min-width':'800px!important', 'max-width':'1000px!important'}">
                <v-row no-gutters class="linhas">
                  <v-col md="3">
                    <v-select
                      v-model="filtro_local.quadra"
                      :items="array_quadras"
                      item-text = "empreend_quadra_nome"
                      item-value = "empreend_quadra_nome"
                      label="Quadra"
                      placeholder="Quadra"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-select>
                  </v-col>
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.lote"
                      label="Lote"
                      placeholder="Lote"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3">
                    <v-select
                      v-model="filtro_local.cod_situacao"
                      :items="array_situacoes"
                      item-text = "situacao_nome"
                      item-value = "cod_situacao"
                      label="Situação"
                      placeholder="Situação"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-select>
                  </v-col>
                  <v-col md="3">
                    <v-select
                      v-model="filtro_local.caucionado"
                      label="Caucionado"
                      placeholder="Caucionado"
                      :items="array_sim_nao"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="linhas" style="margin-top: -4px">
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.area_metro_min"
                      label="Metragem Inicial"
                      placeholder="Metragem Inicial"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      type="number"
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.area_metro_max"
                      label="Metragem Final"
                      placeholder="Metragem Final"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      type="number"
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.preco_total_min"
                      label="Preço mínimo"
                      placeholder="Preço mínimo"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      type="number"
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" disabled>
                    <v-text-field
                      v-model="filtro_local.preco_total_max"  
                      placeholder="Preço Final"
                      label="Preço Final"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      type="number"
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-text-field>
                  </v-col>
                
                </v-row>
                <v-row no-gutters class="linhas" style="margin-top: -4px">
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.fase"
                      label="Fase"
                      placeholder="Fase"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3">
                    <v-select
                      v-model="filtro_local.permite_desmembramento"
                      label="Desmembra"
                      placeholder="Desmembra"
                      :items="array_sim_nao"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-select>
                  </v-col> 
                </v-row>
              </v-container>
              <v-divider></v-divider>

              <!-- Botao Limpar ------------------------------------>
              <div 
                class="pa-4 d-flex justify-space-between"
                :style="{ 'background-color':COR_SECUNDARIA }">
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  outlined
                  tabindex="-1"
                  :small="isMobile"
                  @click="limpa_todos()"
                  >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                    >mdi-close</v-icon
                  >
                  <span>Limpar Todos</span>
                </v-btn>
                          
                <!-- Botao Consultar ------------------------------------>
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  tabindex="-1"
                  :small="isMobile"
                  @click="getImovel(1)"
                  >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                    >mdi-magnify</v-icon
                  >
                  <span>Consultar</span>
                </v-btn>
              </div>  
            </v-menu>  
            <v-spacer></v-spacer> 
            <!-- <v-container class="container mt-0 ml-2 pa-0" style="display: flex; align-items: flex-end; align-items: center; justify-content: flex-end; margin-right: 20px;">
              <template > -->
                <v-btn 
                  style=" width: 84px; min-height: 38px; height: 38px; "
                  @click="Novo()"
                  class="btn caption font-weight-medium"
                  elevation="0"
                  v-bind:style="{ 'color':COR_SUBTITULO }">
                  <v-icon color="green">mdi-plus</v-icon> Novo
                </v-btn>
                <v-menu offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      style="min-width:4px; width: 4px; height: 38px; padding-left: 10px !important;"
                      v-bind="attrs"
                      v-on="on"
                      class="btn pr-3"
                      elevation="0">
                      <v-icon class="btn-icon" color="green accent-4">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list class="py-0">
                    <v-list-item
                      v-for="(item, i) in news"
                      :key="i"
                      @click="() => {}"
                      class="px-2"
                    >
                      <v-list-item-title
                        v-if="item.title == 'Importar do Excel'"
                        class="body-2"
                      >
                        <div @click="store_Empreendimento.importarPlanilha = true;" class="body-2">
                          <v-icon v-bind:style="{ 'color':COR_SUBTITULO }" class="mr-2 btn-icon"
                            >mdi-table-arrow-up
                          </v-icon>
                          {{ item.title }}
                        </div>
                      </v-list-item-title>

                      <v-list-item-title
                        v-if="item.title == 'Cadastro Sequencial'"
                        >
                        <div @click="Novo(); store_Empreendimento.acao = 'IS'" class="body-2">
                          <v-icon v-bind:style="{ 'color':COR_SUBTITULO }" class="mr-2 btn-icon"
                            >mdi-circle-edit-outline
                          </v-icon>
                          {{ item.title }}
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>  
              <!-- </template>
            </v-container> -->
      
          </v-container>
          <!-- ----------------------------------------------- -->
          <!-- CONTAINER TABELA -------------------------------------------------------->
          <v-container class="container mx-0 pa-0">
            <v-data-table
              class="tabela rounded-lg mb-0 data-table"
              :items="filteredUnidades"
              ref="dataTable"
              :headers="headers"
              :loading="loading"
              fixed-header
              v-model="selected"
              item-key="cod_imovel"
              dense
              style="white-space: nowrap"
              :height="tableHeight"
              :hide-default-header="true"
              :hide-default-footer="true"
              loading-text="Carregando... aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              :items-per-page="20"
              :page.sync="store_Empreendimento.currentPage"
              @update:page="handlePageChange"
              :multi-sort="multiSort" 
              :single-select="singleSelect" 
              :show-select="showSelect">
              <template v-slot:header>
                <thead>
                  <tr>
                    <th
                      v-for="h in headers"
                      :key="h.value"
                      :class="h.class"
                      :style="h.style">
                      <v-checkbox 
                        v-if="h.value == 'checkbox'"
                        show-select hide-details class="checkbox"
                        color="primary"
                        @change="selecionaTodos($event)"
                        style="width:40px!important" />
                      <div v-else  @click="ordenarColuna(h.value)" style="cursor:pointer">
                        <span
                          class="d-flex flex-nowrap body-2 font-weight-bold" @mouseover="isHovered = true; colunaOrdenadaHover = h.value" @mouseleave="isHovered = false; colunaOrdenadaHover = null">
                          <div v-if="h.sortable && isHovered && colunaOrdenadaHover == h.value && (!ordenacaoDirecao || ordenacaoDirecao === 'asc')">
                            <v-icon
                              small>
                            mdi-arrow-up
                            </v-icon>
                          </div>
                          <div v-else-if="h.sortable && isHovered && colunaOrdenadaHover == h.value && ordenacaoDirecao === 'desc'">
                            <v-icon
                              small>
                            mdi-arrow-down
                          </v-icon>
                          </div>
                         
                          {{h.text}}
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
              </template>

              <template #item="{ item }">
                <tr>
                  <td style="padding-right: 0;">
                    <v-checkbox
                      v-model="item.value"
                      show-select
                      hide-details
                      class="checkbox"/>
                  </td>
                  <td style="padding: 0;">
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(item_menu, i) in items"
                          :key="i"
                          @click="() => {}"
                          class="px-2">
                          <v-list-item-title
                            v-if="item_menu.title == 'Editar'"
                            @click="clickEditar(item)">
                            <v-icon color="green" class="mr-2 icon-menu">
                              mdi-pencil-outline
                            </v-icon>
                            {{ item_menu.title }}
                          </v-list-item-title>
                          <v-list-item-title
                            v-else-if="item_menu.title == 'Excluir'"
                            @click="botaoExcluir(item)">
                            <v-icon color="red" class="mr-2 icon-menu">
                              mdi-delete-outline
                            </v-icon>
                            {{ item_menu.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td style="text-align:center; width: 5%;">
                  {{ item.quadra }}
                  </td>
                  <td style="text-align:center; width: 5%;">
                    {{ item.lote }}
                  </td>
                  <td>
                    <div
                      class="situacao"
                      :style="{ background: item.imovel_situacao.situacao_venda_cor_html, color: item.imovel_situacao.situacao_venda_cor_html_fonte }">
                      {{ item.imovel_situacao.situacao_nome }} {{ item.situacao_complemento ? ` - ${item.situacao_complemento}` : '' }}
                    </div>
                  </td>
                  <td style="text-align:right; width: 1%;">
                    {{ item.area_metro ? formatNumber(item.area_metro) : '' }}
                  </td>
                  <td style="text-align:right; width: 1%;">
                    {{ item.preco_metro ? formatNumber(item.preco_metro) : "" }}
                  </td>
                  <td style="text-align:right; width: 5%;">
                    {{ item.preco_total ? formatarPreco(item.preco_total) : "" }}
                  </td>
                  <td>
                    {{ item.fase }}
                  </td>
                  <td>
                    {{ item.cadastro_prefeitura }}
                  </td>
                  <td>
                    {{ item.permite_desmembramento }}
                  </td>
                  <td>
                    {{ item.caucionado }}
                  </td>
                  <!-- Resto do seu conteúdo da linha da tabela -->
                </tr>
              </template>
            </v-data-table>

            <!-- <pre>{{ store_Empreendimento.array_unidades }}</pre> -->
            <!-- TABELA UNIDADES -------------------------------------------------------->
            <!-- tabela rounded-lg mb-3  -->
            <!-- <v-data-table
              class="tabela rounded-lg mb-0 data-table"
              :items="store_Empreendimento.array_unidades"
              :headers="headers"
              :loading="loading"
              fixed-header
              dense
              style="white-space: nowrap"
              :height="tableHeight"
              :hide-default-header="isMobile"
              :hide-default-footer="true"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              :items-per-page="20"
              :page.sync="store_Empreendimento.currentPage" 
              @update:page="handlePageChange">
              <template #item="{ item }">
                <tr>
                  <td>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(item_menu, i) in items"
                          :key="i"
                          @click="() => {}"
                          class="px-2">
                          <v-list-item-title
                            v-if="item_menu.title == 'Editar'"
                            @click="clickEditar(item)">
                            <v-icon color="green" class="mr-2 icon-menu">
                              mdi-pencil-outline
                            </v-icon>
                            {{ item_menu.title }}
                          </v-list-item-title>
                          <v-list-item-title
                            v-else-if="item_menu.title == 'Excluir'"
                            @click="botaoExcluir(item)">
                            <v-icon color="red" class="mr-2 icon-menu">
                              mdi-delete-outline
                            </v-icon>
                            {{ item_menu.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td style="text-align:center; width: 5%;">
                  {{ item.quadra }}
                  </td>
                  <td style="text-align:center; width: 5%;">
                    {{ item.lote }}
                  </td>
                  <td>
                    <div
                      class="situacao"
                      :style="{ background: item.imovel_situacao.situacao_venda_cor_html, color: item.imovel_situacao.situacao_venda_cor_html_fonte }">
                      {{ item.imovel_situacao.situacao_nome }} {{ item.situacao_complemento ? ` - ${item.situacao_complemento}` : '' }}
                    </div>
                  </td>
                  <td style="text-align:right; width: 1%;">
                    {{ item.area_metro ? formatNumber(item.area_metro) : '' }}
                  </td>
                  <td style="text-align:right; width: 1%;">
                    {{ item.preco_metro ? formatNumber(item.preco_metro) : "" }}
                  </td>
                  <td style="text-align:right; width: 5%;">
                    {{ item.preco_total ? formatarPreco(item.preco_total) : "" }}
                  </td>
                  <td>
                    {{ item.fase }}
                  </td>
                  <td>
                    {{ item.cadastro_prefeitura }}
                  </td>
                  <td>
                    {{ item.permite_desmembramento }}
                  </td>
                  <td>
                    {{ item.caucionado }}
                  </td>
                </tr> 
              </template>
            </v-data-table> -->
                <!-- Paginacao ------------------------------ -->
            <div
              class="paginacao d-flex justify-end align-center"
              style="background-color: #FFFFFF;">
              <span
                style="color:grey; font-size:14px"
                class="mr-3">{{count_dados}}
                linhas</span>
              <v-pagination
                v-model="store_Empreendimento.currentPage"
                :length="paginas"
                total-visible="5"
                @input="handlePageChange"
                aria-controls="dataTable" />
            </div>

            <v-container
            v-if="store_Empreendimento.dialogUnidades">
              <v-dialog
                v-model="store_Empreendimento.dialogUnidades"
                transition="dialog-bottom-transition"
                max-width="840"
                class="pa-0"
                persistent>
                  <UnidadesModal 
                    ref="UnidadesModal"
                    :array_unidades_detalhes="array_unidades_detalhes"
                    :situacao_aux.sync="situacao_aux"
                  />
              </v-dialog>
            </v-container>
          </v-container>
          <!-- FIM CONTAINER TABELA -------------------------------------------------------->
        </div>

        <div v-if="tab == 1">
          <v-row class="d-flex justify-center align-center">
            <v-col cols="6">
              <v-stepper non-linear elevation="0">
              <v-stepper-header>
                <v-stepper-step
                  editable
                  step="1">
                  Enquadramento de mapa
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  editable
                  step="2">
                  Marcação visual
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
            </v-col>
          </v-row>
          <div class="wrapper-btn mt-1 rounded" >
            <div>
              <v-row>
                <v-col cols="6">
                  <v-card 
                    class="pt-3"
                    elevation="0">

                    <v-img :src="baseURL+'imagens/enquadramento.jpeg'" style="margin: auto" width="200" height="auto"></v-img>
                    <v-card-text>
                      <p>O enquadramento de mapa é o processo de selecionar a área e ajustar o zoom de 
                        um mapa em relação ao satélite.</p>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        @click="openModalEnquadramento()"
                        style="margin: auto">
                        Enquadramento de mapa
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                
                <v-col cols="6">
                  <v-card 
                    class="pt-3"
                    elevation="0">

                    <v-img :src="baseURL+'imagens/marcacao.jpeg'" style="margin: auto" width="200" height="auto"></v-img>

                    <v-card-text>
                      <p>Após o enquadramento do mapa, a etapa seguinte é a marcação de polígonos, 
                        que consiste em representar áreas geográficas indicando cada lote.</p>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        @click="openModalMarcacao()"
                        style="margin: auto">
                        Marcação visual
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-container>
    </v-row>


    <v-dialog v-model="dialog_deletar" 
      transition="dialog-bottom-transition" 
      persistent max-width="410" 
      class="pa-0">
      <v-divider></v-divider>

      <v-card elevation="0" class="">
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR este registro?
        </v-card-title>
        <v-card-text>
          <br>
          <span 
            style="font-size: 15px; font-weight:600">
            Quadra: {{ quadra_aux }}
            <br>
            Lote: {{ lote_aux }}
          </span>
          <br>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn 
            id="btn_cancelar" 
            ref="btn_cancelar" 
            class="mr-4" 
            color="primary" 
            text 
            @click="dialog_deletar = false; this.store_Empreendimento.acao = 'C'">
            Cancelar
          </v-btn>

          <v-btn 
            class="btn white--text" 
            color="primary accent-4" 
            :loading="loading_excluir" 
            @click="excluir()">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


 <!--------- DIALOG ENQUADRAMENTO ------------------------------->
    <v-container v-if="dialog_enquadramento">
      <v-dialog
        class="overflow-hidden pa-0"
        persistent
        v-model="dialog_enquadramento">
        <v-card
          :style="{ 'background-color':COR_SECUNDARIA }"
          class="container pa-0"
          style="overflow:hidden">
          <v-card-title
            :style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between py-1 px-2">
            <div></div>
            <span
              class="text-white title-page body-1">Enquadramento de Mapa - {{ store_Empreendimento.empreendimento_edicao.empreend_nome }}
            </span>
           
            <div class="justify-end">
              <v-btn 
                class="btn white--text caption font-weight-medium mr-6" 
                style="text-transform: none"
                outlined
                small
                color="#FFF accent-4">
                Salvar
              </v-btn>
              <v-btn @click="dialog_enquadramento = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text class="wrapper pt-0 px-0 pb-0">
            <div v-show="true">
              <MapaEnquadramento 
                v-bind:style="{ height: getHeight()+'px'}"
                :cod_empreendimento="$route.params.cod_empreendimento"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
 <!--------- FIM DIALOG ENQUADRAMENTO ------------------------------->

 <!--------- DIALOG MARCAÇÃO ------------------------------->
    <!-- <v-container v-if="dialog_marcacao">
      <v-dialog class="overflow-hidden pa-0" v-model="dialog_marcacao" >
        <v-card :style="{ 'background-color':COR_SECUNDARIA }" class="container pa-0" style="overflow:hidden">
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-1 px-2">
            <div></div>
            <span class="text-white title-page body-1">Marcação visual - {{ store_Empreendimento.empreendimento_edicao.empreend_nome }}</span>
           
            <div class="justify-end">
             
              <v-btn @click="dialog_marcacao = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text class="wrapper pt-0 px-0 pb-0">
              <div v-show="true">
                <MapaEdicao
                  v-bind:style="{ height: getHeight()+'px'}"
                  :cod_empreendimento="$route.params.cod_empreendimento"
                />
              </div>

          </v-card-text>
        </v-card>
    </v-dialog> -->
    <v-container v-if="dialog_marcacao">
      <v-dialog
        class="overflow-hidden pa-0"
        persistent
        v-model="dialog_marcacao">
        <v-card
          :style="{ 'background-color':COR_SECUNDARIA }"
          class="container pa-0"
          style="overflow:hidden">
          <v-card-title
            :style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between py-1 px-2">
            <div></div>
            <span
              class="text-white title-page body-1">Marcação visual - {{ store_Empreendimento.empreendimento_edicao.empreend_nome }}
            </span>
           
            <div class="justify-end">
            
              <v-btn @click="dialog_marcacao = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text class="wrapper pt-0 px-0 pb-0">
            <div v-show="true">
              <MapaEdicao 
                v-bind:style="{ height: getHeight()+'px'}"
                :cod_empreendimento="$route.params.cod_empreendimento"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

    <!--------- FIM DIALOG MARCAÇÃO ------------------------------->
    </v-container>

    <!-- btn de rolar ao topo -->
    <v-btn
      v-show="isVisible"
      :color="COR_PRINCIPAL"
      id="myBtn"
      @click="topFunction()"><v-icon
      color="white"
      size="x-large">mdi-chevron-up</v-icon>
    </v-btn>

     <!--------- DIALOG UPLOAD MAPA ------------------------------->
     <v-dialog v-model="dialog_upload_mapa"  max-width="440px">
      <v-card v-bind:style="{ 'background-color':COR_SECUNDARIA }" class="pa-0">
        <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
          <span class="text-white title-page body-1">Novo Mapa</span>
          <v-btn @click="dialog_upload_mapa = false" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="wrapper pt-1 px-0 pb-13">
          <div class="pa-4 pt-2 pb-0 text-body-1">
            <span>Você escolheu subir um novo mapa e para isso ele deve estar:</span>
            <ul>
              <li>para polígonos, com o fundo dos lotes brancos</li>
              <li>ser desenhado nas dimensões e rotação do satélite</li>
              <li>as laterais transparentes</li>
              <li>no máximo de 600 Kbites</li>
            </ul>
          </div>
          <div class="px-7">
            <v-form v-model="valid" ref="form">
              <v-row>
                <v-col cols="6" class="pb-0 px-0">
                  <v-text-field
                    v-model="novo_mapa.longitude"
                    label="Longitude"
                    placeholder="Longitude"
                    outlined
                    filled
                    background-color="#FFF"
                    dense
                    required
                    :rules="rulesLongitude"
                    flat
                    clearable
                    class="mr-2"
                    tabindex="0"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0 px-0">
                  <v-text-field
                    v-model="novo_mapa.latitude"
                    label="Latitude"
                    placeholder="Latitude"
                    outlined
                    filled
                    background-color="#FFF"
                    dense
                    required
                    flat
                    clearable
                    tabindex="0"
                    type="number"
                    :rules="rulesLatitude"
                  ></v-text-field>
                </v-col>
               
                <v-col cols="12" class="pt-0 pb-0 px-0">
                  <v-select 
                    label="Tipo de Desenho" 
                    v-model="novo_mapa.tipo_desenho" 
                    filled 
                    light 
                    dense 
                    return-object
                    required
                    outlined
                    item-value="value"
                    item-text="text"
                    :rules="rulesTipoDesenho"
                    background-color="#FFF" 
                    :items="arrayTipoDesenho">
                  </v-select>
                </v-col> 
                <v-col cols="12" class="pt-1 pb-0 px-0">
                  <v-file-input 
                    v-model="image" 
                    @change="readURL" 
                    prepend-icon="mdi-camera" 
                    background-color="#FFF" 
                    small-chips 
                    filled 
                    accept="image/png"
                    light 
                    dense 
                    :rules="rulesImagem"
                    class="mt-n2" label="Enviar Mapa">
                  </v-file-input>
                  <v-img :aspect-ratio="1" class="mb-2" :src="url" v-if="!!image && image.size < 614400" max-width="150px" max-height="250px" style="margin:auto"> </v-img>
                 
                </v-col>

              </v-row>
            </v-form>
          </div>

          <v-divider class="mx-4 mb-1 mt-2"></v-divider>

        </v-card-text>

        <v-footer v-bind:style="{ 'background-color':COR_SECUNDARIA }" absolute elevation="0" class="d-flex justify-center pa-3">
          <v-btn
            @click="dialog_upload_mapa = false"
            class="mr-4 btn caption font-weight-medium"
            color="primary"
            small
            text>
            Cancelar
          </v-btn>

          <v-btn 
            :disabled="!valid" 
            @click="novoMapa()" 
            class="btn white--text caption font-weight-medium" 
            :loading="loading" 
            small
            color="primary accent-4">
            Salvar
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
    <!--------- FIM DIALOG UPLOAD MAPA ------------------------------->
    
  </div>

</template>

<script>
import store_Empreendimento from "../store_Empreendimento";
import store_site from "../../../store/store_site";
import store_usuario from "../../../store/store_usuario";
import UnidadesModal from "../Unidades/UnidadesModal.vue";
import { COR_SUBTITULO, COR_PRINCIPAL, COR_SECUNDARIA_2, COR_SECUNDARIA, COR_BACKGROUND, COR_BACKGROUND_2 } from "../../../services/constantes";
import { baseURL } from "../../../services/API";
import MapaEnquadramento from "../../EmpreendMapa/MapaEnquadramento.vue"
import MapaEdicao from "../../EmpreendMapa/MapaEdicao"
import { formatNumber, resizeImage, blobToBase64 } from "../../../services/funcoes";
import MapaEnquadramentoVue from '../../EmpreendMapa/MapaEnquadramento.vue';

export default {
  name: "Unidades",

  components: {
    UnidadesModal,
    MapaEnquadramento,
    MapaEdicao
  },

  data() {
    return {
      store_site             : store_site,
      store_usuario          : store_usuario,
      store_Empreendimento   : store_Empreendimento,

      COR_SUBTITULO          : COR_SUBTITULO, 
      COR_PRINCIPAL          : COR_PRINCIPAL,
      COR_SECUNDARIA_2       : COR_SECUNDARIA_2,
      COR_SECUNDARIA         : COR_SECUNDARIA,
      COR_BACKGROUND         : COR_BACKGROUND,
      COR_BACKGROUND_2       : COR_BACKGROUND_2,
      loading                : false,
      search                 : null,
      search_input_focus     : false,
      filtro                 : null,
      array_unidades_detalhes: null,
      dialog_deletar         : false,
      unidades_deletar       : null,
      loading_excluir        : false,
      quadra_aux             : null,
      lote_aux               : null,
      menu                   : false,
      currentPage            : 1,
      situacao_aux           : null,
      isVisible              : false,
      formatNumber           : formatNumber,
      tab                    : 0,
      count_dados            : null,
      paginas                : null,
      dialog_enquadramento   : false,
      dialog_marcacao        : false,
      dialog_upload_mapa     : false,
      baseURL                : baseURL,
      valid                  : false,
      image                  : null,
      imageWidth             : 0,
      imageHeight            : 0,
      novo_mapa              : {
        latitude               : null,
        longitude              : null,
      },
      tipo_desenho           : null,
      selectAll              : false,
      ordenacaoDirecao       : 'asc',
      colunaOrdenada         : null,
      isHovered              : false,

      array_situacoes        : [],
      array_quadras          : [],
      multiSort              : false, // Para permitir classificação múltipla
      singleSelect           : false, // Para seleção única (checkbox)
      showSelect             : true,
      selected               : [],
      colunaOrdenadaHover    : null,

      array_sim_nao       : [
        "SIM", "NÃO",
      ],
      arrayTipoDesenho       : [
        { value: "BOLINHAS", text: 'BOLINHAS'},
        { value: "POLIGONOS", text: 'POLÍGONOS'},
      ],

      filtro_local: {
        quadra                 : null,
        lote                   : null,
        cod_situacao           : null,
        area_metro_min         : null,
        area_metro_max         : null,
        preco_total_min        : null,
        preco_total_max        : null,
        fase                   : null,
        permite_desmembramento : null,
        caucionado             : null,
      },
      
      data_picker : {
        'min-width':'150px !important',
        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        //'margin-top':'-15px !important',
        //'color':'red'
      },

      /* TITULOS UNIDADES */
      headers: [
        { text: "", value: "checkbox", style: "max-width:40px!important", sortable: false },
        { text: "", value: "1", sortable: false },
        { text: "Quadra", value: "quadra", align: 'center', sortable: true },
        { text: "Lote", value: "lote", align: 'center', sortable:true},
        { text: "Situação", value: "imovel_situacao.situacao_nome", align: 'center', sortable: true},
        { text: "Metr. (m²)", value: "area_metro", align: 'center', sortable: true},
        { text: "Preço (m²)", value: "preco_metro", align: 'center', sortable: true},
        { text: "Preço (total)", value: "preco_total", align: 'center', sortable: true},
        { text: "Fase", value: "fase", align: 'center', sortable: true},
        { text: "Prefeitura (Cad)", value: "prefeitura_cad", align: 'center', sortable: true},
        { text: "Desmembra", value: "permite_desmembramento", align: 'center', sortable: true},
        { text: "Caucionado", value: "caucionado", align: 'center', sortable: true},
      ],

      /* MENU EDIÇÃO (BUTTON DOTS) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],
      
      /* MENU NOVO (BUTTON DOWN) */
      news: [
        {
          title: "Importar do Excel",
        },
        {
          title: "Cadastro Sequencial",
        },
      ],
      rulesLatitude: [
        (value) => !!value || "A latitude é obrigatória",
      ],
      rulesLongitude: [
        (value) => !!value || "A longitude é obrigatória",
      ],
      rulesTipoDesenho: [
        (value) => !!value || "O tipo de desenho é obrigatório",
      ],
      rulesImagem: [
        (value) => !!value || "A imagem é obrigatória",
      ],
    };

  },

  computed:{
    filteredUnidades() {
      return this.store_Empreendimento.array_unidades
		},

    url() {
      if (!this.image) return;

      if (!!this.image && this.image.size < 614400) {
        let file = this.image;
        return URL.createObjectURL(this.image);
      }
      return false
    },
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 90;
      else{
        // console.log(window.innerHeight)
        return window.innerHeight - 370;

      }
    }
  },

  created() {
    this.store_Empreendimento.array_unidades = [];
  },

  mounted() {
    this.store_Empreendimento.importarPlanilha = false;
    let table = document.querySelector('.v-data-table__wrapper')
    let scroolTop = document.querySelector('#myBtn')
    table.addEventListener('scroll', () => {
      table.scrollTop > 3000 ? scroolTop.style.display = 'block' : scroolTop.style.display = 'none'
    })
    this.getSituacoes();
    this.getQuadras();

    this.store_Empreendimento.array_unidades = [];
    setTimeout(() => {
      if (store_Empreendimento.acao === 'C') {
        this.getImovel();
      }
      else{
        this.store_Empreendimento.array_unidades.cod_imoveltipo = ''
        this.store_Empreendimento.array_unidades_detalhes.cod_imoveltipo = '';
        this.store_Empreendimento.array_unidades_detalhes.imovel_situacao.cod_situacao = '';
      }
    }, 500);

  },

  methods: {
    compareStrings(a, b) {
      // Use a função localeCompare para comparar strings, ela lida com acentos e maiúsculas/minúsculas
      return a.localeCompare(b);
    },

    compareNumbers(a, b) {
      // Converta os valores para números e, em seguida, compare
      const numA = parseFloat(a);
      const numB = parseFloat(b);
      return numA - numB;
    },

    ordenarColuna(coluna) {
      if (coluna === this.colunaOrdenada) {
        // A mesma coluna foi clicada, inverte a direção
        this.ordenacaoDirecao = this.ordenacaoDirecao === 'asc' ? 'desc' : 'asc';
      } else {
        // Uma nova coluna foi clicada, define a coluna como a coluna ordenada e a direção como ascendente
        this.colunaOrdenada = coluna;
        this.ordenacaoDirecao = 'asc';
      }

      this.filteredUnidades.sort((a, b) => {
        if(coluna === 'imovel_situacao.situacao_nome'){
          return this.compareStrings(a.imovel_situacao.situacao_nome, b.imovel_situacao.situacao_nome);
        } 
        else if (typeof a[coluna] === 'string' && typeof b[coluna] === 'string') {
          return this.compareStrings(a[coluna], b[coluna]);
        }
         else if (typeof a[coluna] === 'object' || typeof b[coluna] === 'object') {
        // Lidar com valores vazios ou não comparáveis
          return 0;
        } 
        else if (typeof a[coluna] === 'number' && typeof b[coluna] === 'number') {
          return this.compareNumbers(a[coluna], b[coluna]);
        } 
        else {
          // Lida com outros tipos de dados (pode ser necessário ajustar conforme necessário)
          return 0; // Não faz nada
        }
      });

      if (this.ordenacaoDirecao === 'desc') {
        // Inverta a ordem da lista se a direção for descendente
        this.filteredUnidades.reverse();
      }
    },

    selecionaTodos (e) {
      this.selected = []
      this.$nextTick(() => {
        for (const item of this.store_Empreendimento.array_unidades) {
          item.value = e;
        }
        this.selected = [...this.store_Empreendimento.array_unidades]
      })
    },
  
    readURL(file) {
      // console.log("file:::::::::: ", file)
      // START: preview original
      // you can remove this section if you don't like to preview original image

      const reader = new FileReader();
      reader.onload = (event) => {
        this.imageData = event.target.result;

        const image = new Image();
        image.src = this.imageData;
        image.onload = () => {
          this.imageWidth = image.width;
          this.imageHeight = image.height;
        };
      };
      // reader.onload = (e) => {
      //   this.originalImg = e.target.result;
      //   // console.log("originalImg:::::: ", this.originalImg)

      //   if (file.type.match(/image.*/)) {
      //     // START: preview resized
      //     resizeImage({
      //       file: file,
      //       maxSize: 170
      //     }).then(async (resizedImage) => {
      //       this.resizedImg = await blobToBase64(resizedImage);

      //       //this.resizedImg = URL.createObjectURL(resizedImage);
      //       // console.log("this.resizedImg::::", this.resizedImg)
      //     }).catch((err) => {
      //       console.error(err);
      //     });
      //     // END: preview resized
      //   }

      // };
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original
    
    },

    async novoMapa(){
      this.loading = true;

      if (!!this.image && this.image.size > 614400) {
        this.image = null
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = 'O mapa deve ter no máximo 600 kbites';
        this.store_site.alert = true;
        return
      }

      let ls_Res;
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {
        let ls_tipo = this.image.name.split(".")
        let originalImg = await blobToBase64(this.image)
        let ls_mapa_original = originalImg.split("base64,");
        ls_mapa_original = ls_mapa_original[ls_mapa_original.length - 1]
        // console.log("🚀 ~ file: Unidades.vue:1211 ~ novoMapa ~ ls_mapa_original:", ls_mapa_original)
        
        let latitude = Number(this.novo_mapa.latitude)
        let empreend_mapa_ne_bound_lat = latitude - 0.0025
        let empreend_mapa_sw_bound_lat = latitude + 0.0025

        let longitude = Number(this.novo_mapa.longitude)
        let empreend_mapa_ne_bound_lng = longitude - 0.0025
        let empreend_mapa_sw_bound_lng = longitude + 0.0025

        var lo_JSON = {
          empreend_mapa_latitude      : this.novo_mapa.latitude,
          empreend_mapa_ne_bound_lat  : empreend_mapa_ne_bound_lat,
          empreend_mapa_sw_bound_lat  : empreend_mapa_sw_bound_lat,
          empreend_mapa_longitude     : this.novo_mapa.longitude,
          empreend_mapa_ne_bound_lng  : empreend_mapa_ne_bound_lng,
          empreend_mapa_sw_bound_lng  : empreend_mapa_sw_bound_lng,
          empreend_mapa_tipo_marcacao : this.novo_mapa.tipo_desenho.value,
          mapa_base64                 : ls_mapa_original,
          empreend_mapa_zoom          : 16,
          empreend_mapa_rotacao       : 0,
          empreend_mapa_width         : this.imageWidth,
          empreend_mapa_height        : this.imageHeight,
          cod_empreendimento          : this.store_Empreendimento.empreendimento_edicao.cod_empreendimento,
          titulo_ocorrencia           :'Novo Mapa'

        };
      //  console.log('json', lo_JSON);
      //  return
        ls_Res = await this.store_Empreendimento.MapaPut(lo_JSON);
        if (ls_Res.result.message == "success") {
          this.store_site.alert_cor = "green";
          this.store_site.alert_type = "success";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result;
          this.store_site.alert = true;

          this.store_Empreendimento.empreendimento_edicao.empreend_mapa_url = `.public/imagens/empreendimento/mapas/empresa_${this.store_usuario.user.cod_empresa}/mapa_${lo_JSON.cod_empreendimento}.png`
          console.log("🚀 ~ file: Unidades.vue:1266 ~ novoMapa ~ this.store_Empreendimento.empreendimento_edicao:", this.store_Empreendimento.empreendimento_edicao)
        } else {
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_cor = "red";
          this.store_site.alert_type = "warning";
          this.store_site.alert_msg = ls_Res.errors;
          this.store_site.alert = true;
        }


        this.dialog_upload_mapa = false
        this.loading = false;
        this.dialog_enquadramento = true
     
      }
    },

		getHeight() {
			return window.innerHeight - 64 - 90;
		},

    openModalEnquadramento(){
      console.log("🚀 ~ file: Unidades.vue:1090 ~ openModalEnquadramento ~ his.store_Empreendimento.empreendimento_edicao.empreend_mapa_url:", this.store_Empreendimento.empreendimento_edicao.empreend_mapa_url)
      if (!this.store_Empreendimento.empreendimento_edicao.empreend_mapa_url) {
        this.dialog_upload_mapa = true
      }
      else{
        this.dialog_enquadramento = true
      }
    },

    openModalMarcacao(){
      this.dialog_marcacao = true
    },
   
    topFunction() {
      //  // Posiciona o Scrool no começo da tabela 
      this.$vuetify.goTo('.data-table tbody tr',
                          { offset: 60,
                            container: '.v-data-table__wrapper'
                          }
                        )
                        
    },

    clickEditar(item){
      this.situacao_aux = item.imovel_situacao.situacao_venda_nome
      console.log("🚀 ~ file: Unidades.vue:1318 ~ clickEditar ~ item:", item)
      this.array_unidades_detalhes = {...item}; 
      this.array_unidades_detalhes.acao = 'E'
      this.store_Empreendimento.dialogUnidades = true; 
    },
    async getSituacoes(){
      const la_Situacoes = await this.store_Empreendimento.getSituacoes({ cod_empreendimento: this.$route.params.cod_empreendimento });
      this.array_situacoes = la_Situacoes.filter((value) => value.situacao_tipo === "Mapa de Venda" )
      
    },

    async getQuadras(){
      const la_Quadras = await this.store_Empreendimento.getQuadras({ cod_empreendimento: this.$route.params.cod_empreendimento });
      this.array_quadras = la_Quadras;
    },

    limpa_todos() {
      for(let prob in this.filtro_local){
        //console.log('limpando: ' , this.filtro_local[prob])
        this.filtro_local[prob] = null
      }
      this.store_Empreendimento.filtro_unidades = {};
    },

    abriuConsulta(val) {
      // console.log( 'abriuConsulta', this.dados_editar)
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = {...this.store_Empreendimento.filtro_unidades};
        });
      }
    },

    async busca(page) {      
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },

    handlePageChange(value) {
      this.currentPage = value;
      // Posiciona o Scrool no começo da tabela 
      this.$vuetify.goTo('.data-table tbody tr',
                    { offset: 60,
                      container: '.v-data-table__wrapper'
                    }
      )
    },

    Novo() {
      this.situacao_aux = null
      this.array_unidades_detalhes = {};
      this.array_unidades_detalhes.acao = 'I'
      this.store_Empreendimento.dialogUnidades = true;
    },

    async getImovel() {
      this.loading = true;
      this.menu    = false;

      this.store_Empreendimento.filtro_unidades = {...this.filtro_local};
      
      var lo_params = { ...this.store_Empreendimento.filtro_unidades };
      // Limpando o objeto Empreendimento para enviar a API e deixando somente os codigos
      if (!!this.store_Empreendimento.filtro_unidades.quadra?.empreend_quadra_nome) {
        lo_params.quadra = this.store_Empreendimento.filtro_unidades.quadra.empreend_quadra_nome;
      }

      if (!!this.store_Empreendimento.filtro_unidades.cod_situacao?.cod_situacao) {
        lo_params.cod_situacao = this.store_Empreendimento.filtro_unidades.cod_situacao.cod_situacao;
      }
    
      // console.log('params', lo_params);
      lo_params.cod_empreendimento = this.$route.params.cod_empreendimento
      const la_Unidades = await this.store_Empreendimento.getImovel(lo_params);
      if (la_Unidades === 'Não possui dados') {
        this.store_Empreendimento.array_unidades = []
      }
      else{
        this.store_Empreendimento.array_unidades = la_Unidades.rows;
        this.count_dados = la_Unidades.rows.length;
        this.paginas = Math.ceil(this.count_dados / 20)
      }
      this.loading = false
    },

    formatarPreco(value) {
      var valor_formatado = value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
      return valor_formatado
    },

    async excluir(){
      this.loading_excluir = true;

      const la_Excluir = await this.store_Empreendimento.ImovelDelete({cod_imovel: this.unidades_deletar})

      this.loading_excluir = false;
      this.dialog_deletar = false;

      if (la_Excluir.message == "success") {
        this.store_site.alert_cor = "green";
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_type = "success";
        this.store_site.alert_msg = la_Excluir.result;
        this.store_site.alert = true;

        const i = this.store_Empreendimento.array_unidades.findIndex(element => element.cod_imovel == this.unidades_deletar)
        if (i !== -1) {
          this.store_Empreendimento.array_unidades.splice(i, 1);
      }
      } else {
        this.store_site.alert_timeout = 20000;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = 'Não foi possível excluir essa unidade!';
        console.log('errors',la_Excluir.errors)
        this.store_site.alert = true;
      }
    },

    botaoExcluir(item){
      this.unidades_deletar = item.cod_imovel ; 
      this.quadra_aux = item.quadra ; 
      this.lote_aux = item.lote ; 

      this.dialog_deletar = true
    },
  },
};
</script>

<style scoped>
#Unidades {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  width: 100%;
}

#myBtn {
  position: fixed;
  border-radius: 50px;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
  min-width: 40px;
  width: 45px;
  height: 42px;
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;

}

@-webkit-keyframes fadeInFromNone {
  0% {
        display: none;
        opacity: 0;
  }
  25% {
      display: none;
      opacity: 0.2;
  }

  50% {
      display: block;
      opacity: 0.4;
  }
  50% {
      display: block;
      opacity: 0.7;
  }

  100% {
      display: block;
      opacity: 0.8;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
        display: none;
        opacity: 0;
  }
  25% {
      display: none;
      opacity: 0.2;
  }

  50% {
      display: block;
      opacity: 0.4;
  }
  50% {
      display: block;
      opacity: 0.7;
  }

  100% {
      display: block;
      opacity: 0.8;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
        display: none;
        opacity: 0;
  }
  25% {
      display: none;
      opacity: 0.2;
  }

  50% {
      display: block;
      opacity: 0.4;
  }
  50% {
      display: block;
      opacity: 0.7;
  }

  100% {
      display: block;
      opacity: 0.8;
  }
}
@keyframes fadeInFromNone {
  0% {
        display: none;
        opacity: 0;
  }
  25% {
      display: none;
      opacity: 0.2;
  }

  50% {
      display: block;
      opacity: 0.4;
  }
  50% {
      display: block;
      opacity: 0.7;
  }

  100% {
      display: block;
      opacity: 0.8;
  }
}

@media(max-width: 599px) {
  #Unidades {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#Unidades::-webkit-scrollbar {
  width: 5px;
}

#Unidades::-webkit-scrollbar-button {
  padding: 1px;
}

#Unidades::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#Unidades::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #Unidades::-webkit-scrollbar {
    width: initial;
  }

  #Unidades::-webkit-scrollbar-button {
    padding: initial;
  }

  #Unidades::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #Unidades::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
}

.search-input, .search-input-longo {
  min-width: 400px;
  max-width: 400px;
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }
  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */
  }
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .false-filter {
    background-color: var(--COR_BACKGROUND);
    height: 38px!important;
    min-width: 400px!important;
    /* max-width: 900px!important; */
    border-radius: 5px;
  }

  .false-icon {
    cursor: pointer;
  }
.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.btn {
  width: 100px;
}

.linhas {
    height:55px;
    background-color:transparent;
  }

.btn-icon {
  font-size: 20px;
}

.container{
  background-color: #FFF!important;
  
}

#container {
  width: 100%;
  height: 100vh; /* 100% of the viewport height */
}

iframe {
  width: 100%;
  height: 100%;
  border: none; /* Remove iframe border if desired */
}

.checkbox {
  margin-bottom: 3px;
  margin-top: 1px;
}

.situacao {
  color: #f0f0f0;
  padding: 2px 12px;
  border-radius: 25px;
  min-height: 24px;
  min-width: 250px;
  max-width: 350px;
  opacity: 0.6;
  text-align: center;
  margin-top:4px;
  margin-bottom:4px;
  margin-left:4px;
}

.header-text {
  text-align: center
}

</style>